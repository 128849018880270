import './App.css';
import background from './background.png'
import anytracIcon from './anytrac.png'
import gpssim from './gpssim.png'
import React from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ backgroundImage: `url(${background})` }}>
        <div className="flexbox-row-container">
          <a href='https://play.google.com/store/apps/details?id=io.anytrac.gpssim&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <div className="flexbox-column-container">              
              <img className="store-icon" src={gpssim}/>
              <img className="play-store-badge" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </div>
          </a>
          <a className="store-badge" href="https://apps.apple.com/us/app/anytrac/id1511267373?itsct=apps_box_badge&amp;itscg=30200">
            <div className="flexbox-column-container">
              <img className="store-icon" src={anytracIcon}/>
              <img className="app-store-badge" src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1588464000&h=b9aef856addff5c806b2c3def22c1db9' alt="Download on the App Store"/>
            </div>              
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
